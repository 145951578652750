@font-face {
  font-family: 'Space Mono';
  src: url(./fonts/spacemono.woff2);
}

@font-face {
  font-family: 'Druk';
  src: url(./fonts/DrukWideCy-Bold-Web.woff2);
}


h1, h2, h3, span {
  margin: 1em !important;
  font-family: 'Druk' !important;
}

img {
  pointer-events: none;
}

h4 {
  font-family: "Space Mono";
  margin: 1em !important;
}

.App {
  text-align: center;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.logo-home {
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 5;
  min-height: 25px;
  height: 10vw;
  max-height: 50px;
  mix-blend-mode: difference;
}

.logo-home-left {
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 5;
  min-height: 25px;
  height: 10vw;
  max-height: 50px;
  mix-blend-mode: difference;
}


.hidden {
  display: none;
}

#playButton {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: scale(5, 5);
}

.fullscreen {
  width: 100%;
  height: 100%;
}

.fullscreen>video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.play {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 64px;
  height: 64px;
  fill: black;
}

.stopped {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 44px;
  height: 44px;
  fill: #9e9e9e;
}

.logo {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 256px;
  height: 256px;
}

.buttonGroup {
  position: absolute;
  bottom: 50px;
  left: 50vw;
  transform: translateX(-50%);
}

.ui .button {
  background: rgba(255, 255, 255, 0) !important;
  color: white !important;
}

.Joystick-container {
  position: fixed;
  bottom: 25px;
  pointer-events: none;
}

.Joystick {
  pointer-events: initial;
  width: 100px;
  height: 100px;
}

.Joystick.Panning {
  pointer-events: none;
  width: 104px;
  height: 104px;
  background-image: url(./images/panning.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 99.5%;
}


.Joystick>* {
  border: solid 1px #E1E2DC !important;
}

.Joystick>*>button {
  border: solid 1px #E1E2DC !important;
  background: transparent !important;
}

.Message {
  font-family: 'Space Mono' !important;
  opacity: 0;
  font-size: 20px;
  text-transform: uppercase !important;
  transition: opacity 0.2s ease;
  pointer-events: none;
}

.Countdown {
  opacity: 0;
  font-size: 26px;
  transition: opacity 0.2s ease;
  pointer-events: none;
}

.Visible {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.button {
  opacity: 0.9;
  transition: opacity 0.5s ease;
}

.button:hover {
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

@keyframes dash {
  to {
    stroke-dashoffset: 1;
  }
}

@keyframes fill-in {
  to {
    fill-opacity: 1;
  }
}

.pulse {
  animation: fade 2s infinite ease;
}

.static {
  animation: none;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }

}

.Exit {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 50px;
  background: rgba(255, 255, 255, 0) !important;
  color: white !important;
}

/* Camera View */

.CameraView {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.overlay {
  --border-style: 1px solid rgba(255, 255, 255, 0.5);
  --border-space: 20px;
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}


.hidden {
  opacity: 0;
  display: none !important;
}


.overlay-helper {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay-element {
  padding: 20px;
  margin: 80px;
  width: 150px;
  height: 100px;
  position: absolute;
}


@media only screen and (max-width: 600px) {
  .overlay-element {
    padding: 0px;
    margin: 75px 25px;
    width: 75px;
    height: 75px;
    position: absolute;
  }
}

.overlay-text {
  font-size: 1.75em;
  color: white;
  font-family: monospace;
}

.overlay .top-left {
  border-left: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  left: var(--border-space);
  text-align: left;
}

.overlay .top-right {
  border-right: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  right: var(--border-space);
  text-align: right;
}

.overlay .bottom-left {
  border-left: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  left: var(--border-space);
  text-align: left;
}

.overlay .bottom-right {
  border-right: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  right: var(--border-space);
  text-align: right;
}

#overlay-bottom-left-text {
  position: absolute;
  bottom: var(--border-space);
  left: var(--border-space);
}

#overlay-bottom-right-text {
  position: absolute;
  bottom: var(--border-space);
  right: var(--border-space);
}

/* camera button */

.photo-button {
  width: 100px;
  height: 100px;
  bottom: 50px;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  position: absolute;
  pointer-events: initial;
  cursor: pointer;
}

.circle {
  position: absolute;
  top: 12%;
  left: 12%;
  bottom: 12%;
  right: 12%;
  border-radius: 100%;
  background-color: #ffffff;
  opacity: 0.2;
}

.ring {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  opacity: 0.8;
}

.photo-button .circle, .photo-button .ring {
  transition: all 0.25s ease;
}

.photo-button:hover .circle {
  opacity: 0.75;
}

.photo-button:active .ring {
  opacity: 1;
}

.photo-button:active .circle {
  opacity: 0.5;
}

iframe {
  display: none !important;
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
  .logo-home-left {
    top: 50px;
    left: 50px;
    height: 25px;
    transform: scale(2)
  }

  .logo-home {
    top: 50px;
    right: 50px;
    height: 25px;
  }

  .logo {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .logo-home-left {
    top: 25px;
    left: 25px;
  }

  .logo-home {
    top: 25px;
    right: 25px;
    height: 25px;
  }

  .logo {
    width: 200px;
    height: 200px;
  }
}