html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: initial;
  background-color: white;
  /* background: url(./images/background.webp); */
  background-size: cover;
  height: 100%;
  overflow: hidden;
  color: black !important;
}

#root {
  height: 100%;
}

@supports(padding:max(0px)) {
  html, body, header, footer, video {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}