#launchContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#launchContainer>div {
  text-align: center;
}

.button-launch {
  font-family: 'Druk' !important;
  background-color: rgb(255, 47, 0) !important;
  color: white !important;
  border-radius: 5px;
  margin: 0 0 0 0 !important;
  font-size: 24px !important;
}

.button-launch:hover {
  color: red;
  opacity: 0.95;
}